import {addDays, format} from "date-fns";
import classNames from "classnames";

export const toDayOfWeekFormat = (d: number) => format(addDays(new Date, d - (new Date).getDay()), "eee");

export function createValidator<T>(errors: Partial<Record<keyof T, string | string[]>>) {
    return (key: keyof T, className: string = "") => ({
        className: classNames(className, {"ion-invalid ion-touched": errors[key]}),
        errorText: errors[key] as string,
    });
}
